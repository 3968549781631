/**
 * ? FieldController
 * @package de Zalm Property
 * 
 * Controls state of the input fields
 * use for animation purposes
 */

export default class fieldController {

    focusHandler(event) {
        // On focus always set editState to active
        this.getLabel(event.target).dataset.editState = true;
    }

    blurHandler(event) {
        // On blur check if field has a value
        if (event.target.value !== "")
            // If input is given, keep editState active
            this.getLabel(event.target).dataset.editState = true;
        else
            // Set editState to false if no input is given
            this.getLabel(event.target).dataset.editState = false;
    }

    getLabel(target) {
        // Get the parent object of the targeted field
        return target.closest('.field-wrap');
    }

}