/**
 * ? Case Slider controller
 * @package Name
 * 
 */

export default class SminkSlider {

    constructor(target) {

        this.data = {
            wrapper: target,
            elements: target.getElementsByClassName('smink-slider__wrapper'),
            activeIndex: 0,
            headerText: document.getElementById('cases-heading'),
        };

        [].forEach.call(this.data.elements, (ele, index) => {
            if (window.innerWidth > 480) {
                let active = false;
                if (index == 1) {
                    active = true;
                    this.data.activeIndex = index;
                }
            
                this.setEleData(ele, index, active, true);
                this.addListeners(ele, index);
            } else {
                this.setEleData(ele, index, true, true);
            }
        });

        document.addEventListener('keydown', e => {
            this.keyHandler(e.keyCode);
        });
    }

    setEleData(ele, index, active, initial) {
        ele.dataset.itemActive = active;
        ele.dataset.itemIdex = index;

        // if (initial) {
        //     ele.dataset.href = ele.href;
        //     ele.href = "#";
        // }

        // if (active)
        //     // ! ele.href = ele.dataset.href; TEMP DISABLE
        //     ele.href = '';
        // else
        //     ele.href = "";
    }

    slideSwitcher(dir, specific = false) {
        this.data.activeIndex = (this.data.activeIndex + dir)

        if (specific !== false)
            this.data.activeIndex = specific;

        if (this.data.activeIndex < 0)
            this.data.activeIndex = this.data.elements.length - 1
        if (this.data.activeIndex > this.data.elements.length - 1)
            this.data.activeIndex = 0;

        [].forEach.call(this.data.elements, (ele, index) => {
            let active = false;
            if (index == this.data.activeIndex)
                active = true;

            this.setEleData(ele, index, active, false)
        })

        if (this.data.activeIndex == 0) {
            this.data.headerText.style.transform = `translateY(-175%)`;
        } else {
            this.data.headerText.style.transform = ``;
        }
    }

    addListeners(ele, index) {
        if (window.innerWidth > 480) {
            ele.addEventListener("click", e => {
                e.preventDefault();
                if (this.data.activeIndex !== index) {
                    e.preventDefault();
                    this.slideSwitcher(0, index);
                }
            });

            this.touches = {
                "touchstart": {
                    "x": -1,
                    "y": -1
                },
                "touchmove": {
                    "x": -1,
                    "y": -1
                },
                "touchend": false,
                "direction": "undetermined"
            }

            this.data.wrapper.addEventListener("touchstart", e => {
                this.touchParser(e);
            });
            this.data.wrapper.addEventListener("touchmove", e => {
                this.touchParser(e);
            });
            this.data.wrapper.addEventListener("touchend", e => {
                this.touchParser(e);
            });
        }
    }

    touchHandler(direction) {
        console.log(direction);

        switch (direction) {
            case -1: // LEFT            
                this.slideSwitcher(-1, false);
                break;
            case 1: // RIGHT            
                this.slideSwitcher(+1, false);
                break;
        }
    }

    touchParser(event) {
        let touch;
        if (typeof event !== 'undefined') {
            if (event.target.href != "" || event.target.href != "#")
                this.isHrefClick = event.target.href;
                
            event.preventDefault();
            if (typeof event.touches !== 'undefined') {
                touch = event.touches[0];

                switch (event.type) {
                    case 'touchstart':
                    case 'touchmove':
                        this.touches[event.type].x = touch.pageX;
                        this.touches[event.type].y = touch.pageY;

                        break;
                    case 'touchend':
                        if (this.touches[event.type] === true) {
                            setTimeout(() => {
                                this.touches[event.type] = false;
                            }, 500)
                            return;
                        }
                        this.touches[event.type] = true;

                        if (this.touches.touchstart.x > -1 && this.touches.touchmove.x > -1) {
                            this.touches.direction = this.touches.touchstart.x < this.touches.touchmove.x ? -1 : 1;

                            if (this.touches.touchstart.x == this.touches.touchmove.x)
                                if (this.isHrefClick)
                                    window.location.href = this.isHrefClick;
                                return;
                            this.touchHandler(this.touches.direction);
                        }

                        default:
                            break;
                }
            }
        }
    }

    keyHandler(code) {
        switch (code) {
            case 37: // LEFT
                this.slideSwitcher(-1);
                break;
            case 39: // RIGHT
                this.slideSwitcher(+1);
                break;
        }
    }

}