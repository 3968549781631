function createLightbox(trigger) {
  // Set base classname
  const baseClassName = 'smink-lightbox';
  const embedCode = trigger.dataset.embedCode;

  // Create main lightbox element
  const lightBox = document.createElement('div');
  lightBox.classList.add(baseClassName);

  // Create inner wrapper
  const lightBoxWrapper = document.createElement('div');
  lightBoxWrapper.classList.add(`${baseClassName}__wrapper`);
  lightBox.appendChild(lightBoxWrapper);

  // Create the embed frame
  const embedFrame = createYouTubeEmbed(embedCode);
  const iframeWrapper = document.createElement('div');
  iframeWrapper.innerHTML = embedFrame;
  lightBoxWrapper.appendChild(iframeWrapper);

  // Create close button
  const lightboxClosebutton = document.createElement('button');
  lightboxClosebutton.type = 'button';
  lightboxClosebutton.classList.add(`${baseClassName}__closeButton`);
  lightboxClosebutton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><defs><style>.a{fill:none;stroke:#214659;stroke-linecap:round;stroke-linejoin:round;}</style></defs><title>interface-delete-1</title><line class="a" x1="12.5" y1="1.5" x2="1.5" y2="12.5"/><line class="a" x1="1.5" y1="1.5" x2="12.5" y2="12.5"/></svg>';
  lightBoxWrapper.appendChild(lightboxClosebutton);

  const playButton = createPlayButton();
  trigger.insertAdjacentHTML('afterend', playButton);

  // Add lightbox to the DOM
  document.body.appendChild(lightBox);
  lightBox.active = 'false';
  
  // Add event listeners to main element and close button
  addEventListeners([
    trigger,
    lightboxClosebutton,
  ], lightBox);
}

function createPlayButton() {
  const playButtonSVG = '<svg class="playButtonTrigger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><defs><style>.a{fill:none;stroke:#3ea0c9;stroke-linecap:round;stroke-linejoin:round;}</style></defs><title>entertainment-control-button-play</title><path class="a" d="M1.5,12.351a1.143,1.143,0,0,0,.628,1.01,1.244,1.244,0,0,0,1.226-.047l8.6-5.351a1.122,1.122,0,0,0,0-1.926L3.354.686A1.244,1.244,0,0,0,2.128.639,1.143,1.143,0,0,0,1.5,1.649Z"/></svg>'
  
  return playButtonSVG;
}

function createYouTubeEmbed(embedCode) {
  // Create the youtube embed code
  const wrapper = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${embedCode}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

  return wrapper;
}

function addEventListeners(elements, target) {
  elements.forEach((ele) => {
    ele.addEventListener('click', () => {
      toggleLightbox(target);
    });
  })
}

function toggleLightbox(target) {
  // Create bool from dataset string
  const toggle = target.dataset.active === 'true';

  // set dataset of target to the inverse of toggle
  target.dataset.active = !toggle;
}

export {
  createLightbox,
  addEventListeners,
  toggleLightbox,
}
