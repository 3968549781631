/**
 * ? SVG Border controller
 * @package Name
 * 
 */

export default class MenuController {
    constructor() {
        this.data = {
            header : document.getElementById('headerContainer'),
            scrolled : false,
            open: false,
            isHome: false
        }

        if (document.body.dataset.isPageFront == "true")
            this.data.isHome = true;

        this.scrollController(this.data.isHome);
        document.getElementById('openNavigation').addEventListener('click', e => {
            this.data.open = !this.data.open;
            this.toggleNavigation();
        })
    }

    scrollController(isHome) {
        if (isHome) {
            window.addEventListener('scroll', e => {                        
                if (window.scrollY >= 75) {
                    this.data.scrolled = true;
                } else {
                    this.data.scrolled = false;
                }
                this.data.header.dataset.scrolled = this.data.scrolled;
            }, { passive: true })
        } else {
            this.data.header.dataset.scrolled = true;
        }
    }

    toggleNavigation() {
        this.data.header.dataset.menuOpen = this.data.open;
        this.data.header.classList.toggle('menuOpen');
        document.body.classList.toggle('menuIsOpen');
    }
}