/**
 * ? SVG Border controller
 * @package Name
 * 
 */


import SVG from 'svg.js';
import ease from './svg.easing';

export default class SVGController {
    constructor(target, color = "blue--400", opacity = .75, offset = "100%", overflowX = 10, overflowY = 10) {
        this.colors = {
            'blue--900': '#214659',
            'blue--400': '#3ea0c9',
            'blue--100': '#a0b5bf'
        }

        this.data = {
            target: target,
            color: this.colors[color],
            opacity: opacity,
            overflowX: overflowX,
            overflowY: overflowY,
            offset: offset
        }

        if (target.classList.contains('wp-block-group'))
            this.data.offset = "800%"

        this.getInlineData();
        this.draw();
        this.addListeners();
        window.addEventListener('resize', e => {
            this.reDraw()
        })
    }

    draw() {
        this.draw = SVG(this.data.target).size(this.calcSize().x, this.calcSize().y),
            this.rect = this.draw.rect('100%', '100%').attr({
                'fill': 'none',
                'stroke': this.hexToRgbA(),
                'stroke-width': 35,
                'stroke-dasharray': '200%',
                'stroke-dashoffset': 0,
                'stroke-linecap': 'but'
            })
    }

    reDraw() {
        this.draw.size(this.calcSize().x, this.calcSize().y);
    }

    calcSize() {
        let sizes = {
            x: (this.data.target.offsetWidth + this.data.overflowX),
            y: (this.data.target.offsetHeight + this.data.overflowY)
        }

        return sizes;
    }

    getInlineData() {
        const target = this.data.target;

        let color = target.dataset.color,
            overflowX = target.dataset.overflowX,
            overflowY = target.dataset.overflowy,
            offset = target.dataset.offset;

        if (!target.dataset.color)
            color = this.data.color;
        if (!target.dataset.overflowX)
            overflowX = this.data.overflowX;
        if (!target.dataset.overflowy)
            overflowY = this.data.overflowY;
        if (!target.dataset.offset)
            offset = this.data.offset;

        this.data.color = this.colors[color];
        this.data.overflowX = parseInt(overflowX, 10);
        this.data.overflowY = parseInt(overflowY, 10);
        this.data.offset = parseInt(offset, 10);
    }

    addListeners() {
        let options = {};
        let observer = new IntersectionObserver((entries, observe) => {
            this.observerHandler(entries, observe);
        }, options);
        observer.observe(this.data.target);
    }

    observerHandler(entries, observe) {        
        entries.forEach(entry => {
            if (entry.isIntersecting)
                this.callAnimation();      
        });
    }

    callAnimation() {
        this.reDraw();
        this.rect.animate(2000, 'quartInOut').attr({
            'stroke-dashoffset': this.data.offset
        })
        
    }

    hexToRgbA() {
        let color = this.data.color;
        if (!color)
            color = "#3ea0c9";
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
            c = color.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + this.data.opacity + ')';
        }
        throw new Error('Bad Hex: ' + color);
    }
}