/**
 * ? Package entry point
 * @package Name
 *
 * Main package Smink js files
 */

// Import modules
import SVGController from './smink.svgController';
import MenuController from './smink.menuController';
import fieldController from './smink.fieldController';
import SminkSlider from './smink.caseSlider';
import Rellax from 'rellax';
import { createLightbox, addEventListeners, toggleLightbox } from './smink.lightbox';

// Init lightbox
const lightboxTarget = document.getElementById('lightboxTrigger');
if (typeof lightboxTrigger !== 'undefined') {
  createLightbox(lightboxTrigger);
}

// Inisitalize classes
const menuController = new MenuController(),
  fieldHandler = new fieldController();

const borderElements = document.getElementsByClassName('smink-border');
[].forEach.call(borderElements, (ele) => {
  new SVGController(ele);
});

const sliders = document.getElementsByClassName('smink-slider');
[].forEach.call(sliders, (ele) => {
  new SminkSlider(ele);
});

const rellax = new Rellax('.rellax');

/**
 * ? Ninja forms field controller
 * * smink.fieldController.js
 *
 * Adds data attibute to control wether the label needs
 * to be styled as a placeholder istead of label
 */
let ninjaFields;

// Add blur and focus events to each of the ninja form fields
const addFormListeners = () => {
  [].forEach.call(ninjaFields, (field) => {
    field.addEventListener('focus', (field) => {
      fieldHandler.focusHandler(field);
    });
    field.addEventListener('blur', (field) => {
      fieldHandler.blurHandler(field);
    });
  });
};

// Listen for the ninja form ready callout
jQuery(document).on('nfFormReady', function () {
  ninjaFields = document.querySelectorAll('input.ninja-forms-field, textarea.ninja-forms-field');
  addFormListeners();
});

// Sidebar menu control
const sidebarMenu = document.getElementById('sideMenu');
/*
let sizeArray = [];

if (sidebarMenu){
    const parentItems = sidebarMenu.getElementsByClassName('menu-item-has-children');
    [].forEach.call(parentItems, (item, index) => {
        sizeArray.push(item.offsetHeight);
        item.dataset.isActive = false;

        item.addEventListener('click', e => {
            if (e.target.nodeName == "A")
                return;

            e.preventDefault();
            
            const isActive = (item.dataset.isActive == 'true');       
            item.dataset.isActive = !isActive;
            if (!isActive)
                item.style.maxHeight = `${sizeArray[index]}px`;
            else
                item.style.maxHeight = ``;
        });
    });
}
*/

// Single page hero margins
const isHeroSingle = document.querySelector('.single-page--hero');
let heroFiller = null;
let heroHeight = 0;

const setHeroMargins = (heroHeight) => {
  if (sidebarMenu) sidebarMenu.style.marginTop = `${heroHeight}px`;
  document.querySelector(
    '.single-page--hero .heroFiller',
  ).nextElementSibling.style.marginTop = `${heroHeight}px`;
};

if (isHeroSingle) {
  heroFiller = document.getElementsByClassName('heroFiller')[0];
  heroHeight = heroFiller.offsetHeight;
  setHeroMargins(heroHeight);

  window.addEventListener('resize', () => {
    heroHeight = heroFiller.offsetHeight;
    setHeroMargins(heroHeight);
  });
}

// Job filter
function placeTracker(tracker, target) {
  const { offsetLeft, offsetWidth } = target;

  tracker.style.transform = `translateX(${offsetLeft}px)`;
  tracker.style.width = `${offsetWidth}px`;
}

function initJobFilter(wrapper) {
  const filters = wrapper.querySelectorAll('.jobs-filter__item');
  const tracker = wrapper.querySelector('.jobs-filter__tracker');
  const jobs = document.querySelectorAll('.job-item');

  filters.forEach((filter) => {
    filter.addEventListener('click', (e) => {
      e.preventDefault();

      filters.forEach((filter) => {
        filter.classList.remove('active');
      });

      const { location } = filter.dataset;
      filter.classList.add('active');

      placeTracker(tracker, filter);

      jobs.forEach((job) => {
        if (job.dataset.location === location) {
          job.classList.remove('hidden');
        } else {
          job.classList.add('hidden');
        }
      });
    });
  });

  filters[0].click();
}

const jobFilter = document.querySelector('.jobs-filter');
if (jobFilter) {
  initJobFilter(jobFilter);
}
